import { Flex, Container } from '@chakra-ui/react'
import { GetServerSidePropsContext } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

import { ProjectsAPI } from '@miimosa/api/v3'
import { CountryCode, FrontSearchProjectType, Lang, v3toFrontSearchProject } from '@miimosa/common'
import { parseLocale } from '@miimosa/design-system/lib/hooks/use_platform'
import { Testimonial, testimonials } from '@miimosa/directus'

import {
  Commitments,
  TestimonialStories,
  Impacts,
  Engagements,
  History,
  Hero,
  NewProjects,
} from '@components/pages/home'

const getTestimonialsFromDirectus = async (): Promise<Testimonial[]> => {
  try {
    const {
      data: { data },
    } = await testimonials({ displayHome: true })
    return data
  } catch (err) {
    return []
  }
}

const getBeProjects = async ({ lang }: { lang: Lang }) => {
  const projectsAPI = new ProjectsAPI()

  try {
    const results = await projectsAPI.getProjectsFromSearch({
      query: '',
      type_filter: 'PROJECT_TYPE_UNSPECIFIED',
      categories_filter: [],
      around_me_filter: undefined,
      pagination: undefined,
      state_filter: ['PROJECT_STATE_FUNDED', 'PROJECT_STATE_PUBLISHED'],
      regional_code_filter: [],
      preset: 'PRESET_UNSPECIFIED',
      legacy_pagination: {
        items_per_page: 8,
        page: 1,
      },
      campaign_filter: [],
      labels_filter: [],
      branch_code: `mii_be`,
      locale: lang,
      collect_type_filter: 'COLLECT_TYPE_UNSPECIFIED',
      partner_filter: [],
      collect_goal_type_filter: ['COLLECT_GOAL_TYPE_AMOUNT', 'COLLECT_GOAL_TYPE_QUANTITY'],
      slug_filter: [],
    })

    if (results.kind === 'success') {
      return results.data.projects.map((project) => v3toFrontSearchProject({ ...project, imageFileSize: 'high' }))
    } else {
      return []
    }
  } catch (err) {
    return []
  }
}

export const getServerSideProps = async (context: GetServerSidePropsContext) => {
  const { locale } = context
  const { lang, platform } = parseLocale(locale)

  return {
    props: {
      ...(await serverSideTranslations(locale || 'fr', ['common', 'home'])),
      testimonials: await getTestimonialsFromDirectus(),
      projects: await getBeProjects({ lang: lang }),
      platform,
    },
  }
}

const Home = ({
  testimonials,
  projects,
  platform,
}: {
  testimonials: Testimonial[]
  projects: FrontSearchProjectType[]
  platform: CountryCode
}) => {
  return (
    <Flex direction="column" w="full">
      <Hero />
      <Flex w="full" bg="creamy" direction="column" zIndex={1}>
        <Container variant="boxed" w="full" flexDir="column" alignItems="center">
          <History />
        </Container>
      </Flex>
      <Flex w="full" bg="white" direction="column">
        <Engagements />
      </Flex>
      {testimonials && <TestimonialStories testimonials={testimonials} />}
      {platform == 'be' && <NewProjects projects={projects} px={{ base: 10, md: '60px' }} />}
      <Commitments />
      <Impacts />
    </Flex>
  )
}

export default Home
